var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"secondary--text text--lighten-2",attrs:{"flat":"","dense":"","light":""}},[_c('v-toolbar-title',[_c('router-link',{attrs:{"to":"/main/admin/users"}},[_vm._v(" Users ")]),_c('span',{staticClass:"text-subtitle-1"},[_c('span',{staticClass:"mr-1"},[_vm._v("/")]),_vm._v("New ")])],1)],1),_c('v-container',[_c('v-card',{attrs:{"width":"75%"}},[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(!_vm.canAddUsers),expression:"!canAddUsers"}],attrs:{"type":"warning"}},[_vm._v("You've reached the maximum number of users available with the current license")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"disabled":!_vm.canAddUsers},on:{"submit":function($event){$event.preventDefault();}}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"User ID","mode":"eager","rules":"required|alpha_dash|min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"User ID (unique)","error-messages":errors},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Email","mode":"eager","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-mail (unique)","type":"email","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password","mode":"eager","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"password",attrs:{"type":"password","autocomplete":"new-password","label":"Password","error-messages":errors},model:{value:(_vm.password1),callback:function ($$v) {_vm.password1=$$v},expression:"password1"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Role","items":_vm.roles,"item-text":"name","item-value":"id"},model:{value:(_vm.roleId),callback:function ($$v) {_vm.roleId=$$v},expression:"roleId"}}),_c('ValidationProvider',{attrs:{"name":"Display name","mode":"eager","rules":"min:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Display Name","error-messages":errors},model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password confirmation","mode":"eager","rules":"required|confirms:@Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","autocomplete":"new-password","label":"Confirm password","error-messages":errors},model:{value:(_vm.password2),callback:function ($$v) {_vm.password2=$$v},expression:"password2"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"secondary",attrs:{"tile":"","small":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"secondary",attrs:{"tile":"","small":""},on:{"click":_vm.reset}},[_vm._v("Reset")]),_c('v-btn',{staticClass:"primary",attrs:{"tile":"","small":"","disabled":invalid},on:{"click":_vm.submit}},[_vm._v("Save")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }