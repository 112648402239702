




























































import { Component, Vue } from 'vue-property-decorator';
import { IUserProfileCreate } from '@/interfaces';
import {dispatchCreateUser, dispatchGetRoles, dispatchGetUsers} from '@/store/admin/actions';
import { readAdminRoles } from '@/store/admin/getters';
import {readAppSettings} from "@/store/main/getters";

@Component
export default class CreateUser extends Vue {

  $refs!: {
    observer
  }

  public userId: string = '';
  public displayName: string = '';
  public email: string = '';
  public roleId: number = 2;
  public password1: string = '';
  public password2: string = '';
  public canAddUsers: boolean = true;

  public async mounted() {
    await dispatchGetRoles(this.$store);
    await dispatchGetUsers(this.$store);
    const appSettings = await readAppSettings(this.$store);
    this.canAddUsers = !appSettings || !appSettings.seats_available || appSettings.seats_available > 0;
    this.reset();

  }

  get roles() {
    return readAdminRoles(this.$store);
  }

  public reset() {
    this.userId = '';
    this.displayName = '';
    this.email = '';
    this.roleId = 2;
    this.password1 = '';
    this.password2 = '';
    this.$refs.observer.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    this.$refs.observer.validate().then(async () => {
      const profileCreate: IUserProfileCreate = {
        email: this.email,
        user_id: this.userId,
        role_id: this.roleId,
        password: this.password1
      };
      if (this.displayName) {
        profileCreate.display_name = this.displayName;
      }
      try {
        await dispatchCreateUser(this.$store, profileCreate);
        this.$router.push('/main/admin/users');
      } catch (e) {
        console.error(e.message);
      }
    });
  }
}
